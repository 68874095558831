<template>
  <b-card>
    <akrual-data-update class="mb-2" />

    <b-row class="">
      <b-col sm="12" md="12" lg="5" xl="5">
        <b-input-group class="input-group-merge d-flex flex-nowrap mb-2">
          <b-input-group-prepend class="cs-select">
            <v-select v-model="search" :options="searchOptions" :clearable="false" />
          </b-input-group-prepend>
          <b-form-input
            id="x1"
            placeholder="Buscar"
            class="px-1 w-100 mw-250"
            v-mask="'##/####'"
            @keypress.enter="updatePayload($event.target.value)"
            v-if="search.value === 'mes_emissao' || search.value === 'mes_vencimento'"
          />
          <b-form-input
            id="x2"
            placeholder="Buscar"
            class="px-1 w-100 mw-250"
            @keypress.enter="updatePayload($event.target.value)"
            v-else
          />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col sm="12" md="12" lg="7" xl="7">
        <div class="d-flex justify-content-end align-items-center mb-2">
          <b-button-group>
            <b-button
              :variant="selectedButtonVariant.all"
              class="text-dark"
              @click="selectButton('all')"
            >
              <strong>Todos</strong>
            </b-button>
            <b-button
              :variant="selectedButtonVariant.cri"
              class="text-dark"
              @click="selectButton('cri')"
            >
              <strong>CRI</strong>
            </b-button>
            <b-button
              :variant="selectedButtonVariant.cra"
              class="text-dark"
              @click="selectButton('cra')"
            >
              <strong>CRA</strong>
            </b-button>
            <b-button
              :variant="selectedButtonVariant.cr"
              class="text-dark"
              @click="selectButton('cr')"
            >
              <strong>CR</strong>
            </b-button>
            <b-button
              :variant="selectedButtonVariant.deb"
              class="text-dark"
              @click="selectButton('deb')"
            >
              <strong>DEB</strong>
            </b-button>
            <b-button
              :variant="selectedButtonVariant.nc"
              class="text-dark"
              @click="selectButton('nc')"
            >
              <strong>NC</strong>
            </b-button>
            <b-button
              :variant="selectedButtonVariant.in_progress"
              class="text-dark"
              @click="selectButton('in_progress')"
            >
              <strong>Ativas</strong>
            </b-button>
          </b-button-group>
          <feather-icon
            icon="FilterIcon"
            size="24"
            class="cursor-pointer ml-1"
            @click="showFiltersButton = true"
          />
        </div>
      </b-col>
    </b-row>
    <b-table responsive :items="items" :fields="fields"> 
      
      <template #cell(actions)="data">
        <b-dropdown left variant="none">
          <template #button-content>
            <feather-icon icon="EyeIcon" size="24" />
          </template>
          <b-dropdown-item @click="showAdminEmission('assignor', data.item)">Cedente</b-dropdown-item>
          <b-dropdown-item @click="showAdminEmission('investor', data.item)">Investidor</b-dropdown-item>
          <b-dropdown-item @click="showAdminEmission('provider', data.item)">Prestador</b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0 mr-1">Mostrar</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" />
          <label class="mr-2 ml-1">registros</label>
        </div>
        <div class="d-flex align-items-center">
          <span class="text-muted"> {{ showing }} </span>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          aria-controls="my-table"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>

    <datatable-filters-handler-sidebar
      :is-task-handler-sidebar-active="showFiltersButton"
      @closeSideBar="showFiltersButton = false"
      @updateFilterValues="updateFilterValues"
    />
  </b-card>
</template>

<script>
import {
  moneyFormat,
  dateTimeToDateFormatter,
  dateTimeFormatter,
  moneyFormatDatatable,
} from '@core/comp-functions/data_visualization/datatable'
import {
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BButtonGroup,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AkrualDataUpdate from '@/views/admin/components/AkrualDataUpdate.vue'
import DatatableFiltersHandlerSidebar from '@/views/common/crud/components/DatatableFiltersHandlerSidebar.vue'

export default {
  name: 'DataManagement',
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BInputGroup,
    BButton,
    BButtonGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    BFormInput,
    BPagination,
    AkrualDataUpdate,
    DatatableFiltersHandlerSidebar,
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      showFiltersButton: false,
      items: [],
      fields: [
        {
          label: 'Tipo',
          key: 'tipo_emissao',
          sortable: false,
        },
        {
          label: 'Nome da emissão',
          key: 'nome_fantasia',
          sortable: false,
        },
        {
          label: 'Valor da emissão',
          key: 'valor_global_emissao',
          sortable: false,
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Emissão',
          key: 'numero_emissao',
          sortable: false,
        },
        {
          label: 'Vencimento',
          key: 'data_vencimento',
          sortable: false,
          formatter: dateTimeToDateFormatter,
        },
        {
          label: 'Data',
          key: 'data_emissao',
          sortable: false,
          formatter: dateTimeToDateFormatter,
        },
        {
          label: 'Código IFB3',
          key: 'codigo_cetip',
          sortable: false,
        },
        {
          label: 'CNPJ cedente',
          key: 'cedentes_documentos',
          sortable: false,
        },
        {
          label: 'ISIN',
          key: 'codigo_isin',
          sortable: false,
        },
        {
          label: 'Emissor',
          key: 'emissor',
          sortable: false,
        },
        {
          label: 'Agente Fiduciário',
          key: 'agente_fiduciario',
          sortable: false,
        },
        {
          label: 'Remuneração',
          key: 'remuneracao',
          sortable: false,
        },
        {
          label: 'Ações',
          key: 'actions',
          sortable: false,
        }
      ],
      currentPage: 1,
      search: {
        label: 'Tipo de busca',
        value: '',
      },
      searchQuery: {
        key: '',
        value: '',
      },
      perPage: 10,
      totalRows: 0,
      selectedButton: 'all',
      searchOptions: [
        {
          label: 'Tipo de busca',
          value: '',
        },
        {
          label: 'Por operação/Série',
          value: 'serie',
        },
        {
          label: 'Por emissão',
          value: 'emissao',
        },
        {
          label: 'Por ISIN',
          value: 'codigo_isin',
        },
        {
          label: 'Por IF B3',
          value: 'codigo_cetip',
        },
        {
          label: 'Por agente fiduciário',
          value: 'agente_fiduciario',
        },
        {
          label: 'Por mês da emissão',
          value: 'mes_emissao',
        },
        {
          label: 'Por mês da vencimento',
          value: 'mes_vencimento',
        },
      ],
      perPageOptions: [10, 25, 50, 100],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    showAdminEmission(type, emission) {
      const routeName = {
        assignor: 'admin.assignor.emission.details',
        investor: 'admin.investor.emission.details',
        provider: 'admin.provider.emission.details',
      }[type]
      // Abre na mesma aba
      // this.$router.push({ name: routeName, params: { serie_id: emission.serie_id } })
      // Abre em nova aba
      const routerData = this.$router.resolve({ name: routeName, params: { serie_id: emission.serie_id } })
      window.open(routerData.href, '_blank')
    },
    updatePayload(value) {
      this.searchQuery.key = this.search.value
      this.searchQuery.value = value
      console.log(this.emissoesPayload)
    },
    async getData() {
      try {
        const { data } = await this.$store.dispatch(
          'publicStore/fetchEmissions',
          this.emissoesPayload,
        )
        this.items = data.data
        this.currentPage = data.current_page
        this.totalRows = data.total
      } catch (error) {
        console.log(error)
      }
    },
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
    selectButton(type) {
      this.selectedButton = type
    }
  },
  computed: {
    emissoesPayload() {
      let payload = {
        page: this.currentPage,
        per_page: this.perPage,
      }

      if (this.selectedButton !== 'all') payload.tipo_emissao = this.selectedButton.toUpperCase()
      if (this.selectedButton === 'in_progress') {
        delete payload.tipo_emissao
        payload.ativo = true
      }
      if (this.searchQuery.key && this.searchQuery.value)
        payload[this.searchQuery.key] = this.searchQuery.value

      if (!this.searchQuery.key && this.searchQuery.value) {
        payload.fulltext = this.searchQuery.value
      }

      return payload
    },
    selectedButtonVariant() {
      const types = {
        all: 'outline-primary',
        cri: 'outline-primary',
        cra: 'outline-primary',
        cr: 'outline-primary',
        deb: 'outline-primary',
        nc: 'outline-primary',
        in_progress: 'outline-primary',
      }

      types[this.selectedButton] = 'primary'

      return types
    },
    showingStart() {
      if (this.totalRows === 0) return 0

      return this.currentPage === 1 ? 1 : (this.currentPage - 1) * this.perPage + 1
    },
    showingEnd() {
      let end = this.currentPage * this.perPage
      if (end > this.totalRows) end = this.totalRows

      return end
    },
    showing() {
      return `Mostrando de ${this.showingStart} a ${this.showingEnd} de ${this.totalRows} registros`
    },
  },
  watch: {
    emissoesPayload() {
      this.getData()
    },
  },
}
</script>

<style lang="scss">
.mw-250 {
  max-width: 250px;
}
</style>
