<template>
  <b-card class="card-cs-border card-cs-bg mb-0">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <h4 class="mb-0 mr-1">{{ qtdEmissoes }}</h4>
        <span>Emissões cadastradas no sistema até o momento</span>
      </div>
      <div class="d-flex">
        <div class="d-flex justify-content-between align-items-center mr-2">
          <span class="mr-1"> Última atualização </span>
          <strong> {{ last_update_date | formatDate }} </strong>
        </div>
        <div class="d-flex justify-content-between align-items-center mr-2">
          <span class="mr-1"> Hora </span>
          <strong> {{ last_update_date | formatDateOnlyTime }} </strong>
        </div>
        <b-button variant="primary" @click="updateAkrualData" :disabled="!canUpdate()"> Atualizar dados do Akrual </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { formatDate, formatDateOnlyTime } from '@core/utils/filter'
import { BCard, BButton } from 'bootstrap-vue'

export default {
  name: 'AkrualDataUpdate',
  components: {
    BCard,
    BButton,
  },
  filters: {
    formatDate,
    formatDateOnlyTime,
  },
  data() {
    return {
      last_update_date: null,
      qtdEmissoes: 0,
      lastUpdateClick: null,
    }
  },
  methods: {
    canUpdate() {
      const lastUpdate = this.$store.state.admin.lastAkrualUpdateTime
      if (!lastUpdate) return true
      const minutes = 10 * (60 * 1000) // 10 Minutos
      return (Date.now() - parseInt(lastUpdate)) > minutes
    },
    async updateAkrualData() {
      this.$swal.fire({
        title: 'Atualizando dados!',
        html: 'A atualização dos dados foi solicitada com sucesso!<br><br>O processo irá acontecer em segundo plano e pode levar alguns minutos, não é necessario ficar nesta página!',
        icon: 'success',
      })
      .then((result) => {
        this.$emit('closeForm')
      })
      try {
        await this.$store.dispatch('admin/updateAkrualData')
      } catch (error) {
        console.log(error)
      }
    },
    async lastUpdateAkrualData() {
      try {
        const { data } = await this.$store.dispatch('admin/lastUpdateAkrualData')
        this.last_update_date = data.data.data
        this.qtdEmissoes = data.data.qtd_emissoes
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    this.lastUpdateAkrualData()
  },
}
</script>
